import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Components/Sass/Home.scss'
// import Home from './Components/Pages/Home';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy'
import Home2 from './Components/Pages/Home2';
import MustardOil from './Components/Pages/MustardOil';
import RefinedOil from './Components/Pages/RefinedOil';
import AdminLogin from "./Components/AdminPanel/Login"
import Admin from "./Components/AdminPanel/Admin"
import ContactDB from "./Components/AdminPanel/Database/ContactDB"
import ResetPassword from "./Components/AdminPanel/ResetPassword"
import BlogslistView from "./Components/AdminPanel/Blog/blogslist"
import CreateBlog from "./Components/AdminPanel/Blog/create"
import BlogEdit from "./Components/AdminPanel/Blog/edit"
import Blog from './Components/Pages/Blog';
import BlogDetails from './Components/Pages/BlogDetails';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home2 />} />
          <Route path="/product/kachi-ghani-mustard-oil" element={<MustardOil />} />
          <Route path="/product/refined-soyabean-oil" element={<RefinedOil />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path='/blog' element={<Blog />} />
          <Route path="/show/:id" element={<BlogDetails />} />

          {/* Admin */}
          <Route exact path="/admin-login" element={<AdminLogin />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/contact-form-database" element={<ContactDB />} />
          <Route exact path="/admin-reset-password" element={<ResetPassword />} />

          {/* blog */}
          <Route path="/bloglist" element={<BlogslistView />} />
          <Route path="/create/" element={<CreateBlog />} />
          <Route path="/edit-blog/:id" element={<BlogEdit />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
