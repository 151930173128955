import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import "../Sass/Home.scss"
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link as Links, Element } from 'react-scroll';
import emailjs from '@emailjs/browser';
import fb from "../Firebase/firebase";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpArrow from '../Utilities/UpArrow';

// Images ===============================
import mustard from "../Image/Products/mustard.png"
import refined from "../Image/Products/refined.png"
// import banner from "../Image/Mockup/mockup.png"
import buy from "../Image/buy.png"
import fssai from "../Image/fssai.png"
import banner from "../Image/bg.jpg"
import about from "../Image/about.png"
import benefits from "../Image/benefits.png"
import Header from '../Utilities/Header';
import Footer from '../Utilities/Footer';
import blog from "../Image/2.png"
// =============================================

const DB = fb.firestore()
const Blogslist = DB.collection('blogs');
const CF = DB.collection('Khadyan');

const Home2 = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }

    }, []);

    // products

    const responsive = {
        0: { items: 1 },
        // 568: { items: 2 },
        // 1024: { items: 3 },
    };

    const items = [
        <div className="products-card">
            <img src={mustard} alt="" />
            <div className="products-card-text">
                <h3>Kachi Ghani Mustard Oil</h3>

                <p>Pure Kachi Ghani Mustard Oil, traditionally known as ‘Sarson Ka Tel, is cold-pressed from the finest quality mustard seeds.</p>
                <p>Our dedication in maintaining purity ensures that this cooking oil retains its natural nutrients, robust aroma, and authentic flavors, staying true to the heart of traditional Indian cuisine. Pure cold-pressed Mustard oil is celebrated for its health benefits, particularly its wealth of monounsaturated and polyunsaturated fats, which support heart health. Ideal for Pickling, Frying, and Seasoning, Kachi Ghani Mustard Oil adds a unique pungency and depth to dishes, making it an indispensable addition to any kitchen focused on nutrition and taste.</p>

                <div className="btn">
                    <a href="#buy">Buy Now</a>
                    <Link to={"/product/kachi-ghani-mustard-oil"} href="#">Learn More</Link>
                </div>

            </div>
        </div >,
        <div className="products-card">
            <img src={refined} alt="" />
            <div className="products-card-text">
                <h3>Refined Soyabean Oil</h3>
                <p>Soy oil is the oil extracted from soybeans. Crude soybean oil undergoes further refinement- degumming, refining, bleaching, and deodorizing and forms refined soy oil. Soybean oil is the leading vegetable oil traded in the international markets, next only to palm oil. It has a share of 24% in total vegetable oil consumption. Soy oil accounts for nearly 75% out of total vegetable oil in-take in the US. Soy oil is cholesterol-free and high in polyunsaturated fat. Soy oil is light in flavor and has almost no odor.</p>
                <div className="btn">
                    <a href="#buy">Buy Now</a>
                    <Link to={"/product/refined-soyabean-oil"} href="#">Learn More</Link>
                </div>

            </div>
        </div>
    ];

    // products ends


    // contact form

    const form = useRef();
    const [userData, setUserData] = useState({
        Name: "",
        Email: "",
        Mobile: "",
        Quantity: "",
        Products: ""
    });

    let name, value;
    const postUserData = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUserData({ ...userData, [name]: value });
    }

    //connect with firebase
    const SubmitData = async (e) => {
        e.preventDefault();
        const { Name, Email, Mobile, Quantity, Products } = userData;
        if (Name && Email && Mobile && Quantity && Products) {


            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(userData.Email)) {
                toast.warning('Please enter a valid email address');
                return
            }

            if (typeof Mobile !== "undefined") {

                var pattern = new RegExp(/^[0-9\b]+$/);

                if (!pattern.test(userData.Mobile)) {
                    toast.warning("Please enter only number.");
                    return

                } else if (userData.Mobile.length !== 10) {
                    toast.warning("Please enter 10 digit Mobile number.");
                    return
                }
            }

            try {

                CF.add({
                    Name: Name,
                    Email: Email,
                    Mobile: Mobile,
                    Quantity: Quantity,
                    Products: Products
                }).then((docRef) => {
                    toast.success('Order Received Successfully');
                }).catch((error) => {
                    console.error("error:", error);
                });

                emailjs.sendForm('service_dsa99ik', 'template_tsr1rcs', form.current, '83jtwmNsSV6ZTcYfq')
                    .then((result) => {
                        console.log(result.text);

                    }, (error) => {
                        console.log(error.text);
                    });

                setUserData({
                    Name: "",
                    Email: "",
                    Mobile: "",
                    Quantity: "",
                    Products: ""
                })

                // console.log("Document written with ID: ", docRef.id);
            } catch (e) {
                console.error("Error adding document: ", e);
                toast.warning("Error adding document")
            }
        }
        else {
            toast.warning('Please fill the data');
        }
    };

    // contact form dancing



    // fetching blogs
    const [blogs, Setblogs] = useState([]);

    useEffect(() => {
        // 👇️ scroll to top on page load

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const unsubscribe = Blogslist.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            // Update state
            Setblogs(data);
        });

        // Detach listener
        return unsubscribe;
    }, []);

    // fetching blogs ends


    return (

        <>
            <ToastContainer />
            {/* <UpArrow /> */}

            <Header />
            {/* <div className="banner">
                <div className="banner-shape"></div>
                <div className="banner-shape2"></div>
                <img src={banner} alt="product" />
                <div class="banner-card ">
                    <h1>Khadyan</h1>
                    <p>A brand based and stated from Gurgaon works under edible oils. </p>
                    <Links
                        activeClass="active"
                        to="buy"
                        spy={true}
                        smooth={true}
                        offset={-30}
                        duration={100}
                    >
                        Our Products
                    </Links>
                </div>
            </div> */}

            <div className="banner">
                <img src={banner} alt="banner" />

                <div className="banner-text">
                    <h3>The Golden Secret to</h3>
                    <h3 className='h32'>Radiant Health</h3>
                    <a href="#buy">shop now</a>
                </div>
            </div>

            <div className="khadyan">
                <Element name="about" className="element">
                    <section className="about" id='about'>

                        <div className="title">
                            <h4>About us</h4>
                            <p><span>Value</span> that <span>Matters</span>  most to <span>you</span>
                            </p>
                        </div>
                        <div className="about-body">
                            <div className="about-img">
                                <img src={about} alt="" />
                            </div>
                            <div className="about-text">
                                <p>Khadyan, A brand based and stated from Gurgaon works under edible oils.</p>

                                <p>In this era of adulteration where everybody is looking for pure and safe options either organic or certified, we are here to provide you safe products with a range of edible oils and ensure your good health and smooth lifestyle with some extra greece with <b>KHADYAN OILS</b>.
                                </p>
                                <div className="about-points">
                                    <ul>
                                        <li>

                                            <div className="svg">

                                                <svg clip-rule="evenodd" fill-rule="evenodd" height="43.349mm" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 43.3492 43.3492" width="43.349mm" xmlns="http://www.w3.org/2000/svg"><g id="Layer_x0020_1"><path d="m18.7919 40.4666c-10.3656 0-18.7919-8.4264-18.7919-18.792s8.4263-18.792 18.7919-18.792c4.7533 0 9.2138 1.7264 12.689 4.912-1.1559.6835-2.2714 1.4406-3.3443 2.2601-2.6332-2.1243-5.9073-3.2939-9.3447-3.2939-8.2149 0-14.9137 6.6989-14.9137 14.9138s6.6988 14.9138 14.9137 14.9138c11.4303 0 18.2165-12.2181 13.3002-21.7178.7451-.6929 1.512-1.3645 2.2992-2.0137.2324-.1917.4668-.3816.7029-.5696 1.6446 2.8405 2.49 6.0611 2.49 9.3872-.0002 10.3657-8.4266 18.7921-18.7923 18.7921z" /><path d="m19.2856 31.335c-.2115 0-.3878-.141-.4583-.3173-.0352-.0705-3.0674-7.8976-7.7566-10.8592-.9167-.5641-1.6217-1.1635-1.3397-2.5033.282-1.3045 1.1635-2.0449 2.75-2.3975 2.8205-.5994 5.8526 3.7725 6.9809 5.5707 3.9487-5.7469 11.8463-14.7728 23.3401-15.8305.5545-.065.7595.7569.2468.9872-.1763.0705-16.6062 7.5804-23.2697 25.0679-.1057.1762-.282.282-.4935.282z" /></g></svg>
                                            </div>
                                            <div className="pt">
                                                <h6>Our Vision</h6>
                                                <p><i>“Value that matters most to you”</i> can be different for different people but for Khadyan your health matters the most and our goal is to achieve complete general and modern trade in Delhi-NCR & all other states.</p>
                                            </div>

                                        </li>

                                        {/* <li>

                                            <div className="svg">

                                                <svg clip-rule="evenodd" fill-rule="evenodd" height="43.349mm" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 43.3492 43.3492" width="43.349mm" xmlns="http://www.w3.org/2000/svg"><g id="Layer_x0020_1"><path d="m18.7919 40.4666c-10.3656 0-18.7919-8.4264-18.7919-18.792s8.4263-18.792 18.7919-18.792c4.7533 0 9.2138 1.7264 12.689 4.912-1.1559.6835-2.2714 1.4406-3.3443 2.2601-2.6332-2.1243-5.9073-3.2939-9.3447-3.2939-8.2149 0-14.9137 6.6989-14.9137 14.9138s6.6988 14.9138 14.9137 14.9138c11.4303 0 18.2165-12.2181 13.3002-21.7178.7451-.6929 1.512-1.3645 2.2992-2.0137.2324-.1917.4668-.3816.7029-.5696 1.6446 2.8405 2.49 6.0611 2.49 9.3872-.0002 10.3657-8.4266 18.7921-18.7923 18.7921z" /><path d="m19.2856 31.335c-.2115 0-.3878-.141-.4583-.3173-.0352-.0705-3.0674-7.8976-7.7566-10.8592-.9167-.5641-1.6217-1.1635-1.3397-2.5033.282-1.3045 1.1635-2.0449 2.75-2.3975 2.8205-.5994 5.8526 3.7725 6.9809 5.5707 3.9487-5.7469 11.8463-14.7728 23.3401-15.8305.5545-.065.7595.7569.2468.9872-.1763.0705-16.6062 7.5804-23.2697 25.0679-.1057.1762-.282.282-.4935.282z" /></g></svg>
                                            </div>

                                            <div className="pt">
                                                <h6>Directions For Use</h6>
                                                <p>Mix collagen powder with water and drink.</p>
                                            </div>

                                        </li>

                                        <li>
                                            <div className="svg">

                                                <svg clip-rule="evenodd" fill-rule="evenodd" height="43.349mm" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 43.3492 43.3492" width="43.349mm" xmlns="http://www.w3.org/2000/svg"><g id="Layer_x0020_1"><path d="m18.7919 40.4666c-10.3656 0-18.7919-8.4264-18.7919-18.792s8.4263-18.792 18.7919-18.792c4.7533 0 9.2138 1.7264 12.689 4.912-1.1559.6835-2.2714 1.4406-3.3443 2.2601-2.6332-2.1243-5.9073-3.2939-9.3447-3.2939-8.2149 0-14.9137 6.6989-14.9137 14.9138s6.6988 14.9138 14.9137 14.9138c11.4303 0 18.2165-12.2181 13.3002-21.7178.7451-.6929 1.512-1.3645 2.2992-2.0137.2324-.1917.4668-.3816.7029-.5696 1.6446 2.8405 2.49 6.0611 2.49 9.3872-.0002 10.3657-8.4266 18.7921-18.7923 18.7921z" /><path d="m19.2856 31.335c-.2115 0-.3878-.141-.4583-.3173-.0352-.0705-3.0674-7.8976-7.7566-10.8592-.9167-.5641-1.6217-1.1635-1.3397-2.5033.282-1.3045 1.1635-2.0449 2.75-2.3975 2.8205-.5994 5.8526 3.7725 6.9809 5.5707 3.9487-5.7469 11.8463-14.7728 23.3401-15.8305.5545-.065.7595.7569.2468.9872-.1763.0705-16.6062 7.5804-23.2697 25.0679-.1057.1762-.282.282-.4935.282z" /></g></svg>
                                            </div>

                                            <div className="pt">
                                                <h6>How Collared S/F Works</h6>
                                                <p>Collared S/F Orange Flavor Sachet 12.5 gm works by enhancing bone health by promoting the production of bone cells and cartilage and assisting in wound repair and healing.</p>
                                            </div>

                                        </li> */}
                                    </ul>

                                </div>

                                <div className="fssai">
                                    <div className="fssai-body">
                                        <img src={fssai} alt="fssai" />
                                        <div className="fssai-text">
                                            <h4> FSSAI number</h4>
                                            {/* <p>10823005000462</p> */}
                                            <p>1082**********</p>
                                        </div>
                                    </div>

                                    <div className="fssai-body">
                                        <div className="fssai-text">
                                            <h4>GST NUMBER</h4>
                                            {/* <p>06EKDPA7029A1ZS</p> */}
                                            <p>06EK***********</p>

                                        </div>
                                    </div>
                                </div>

                                {/* <Links
                                    activeClass="active"
                                    to="buy"
                                    spy={true}
                                    smooth={true}
                                    offset={-30}
                                    duration={100}
                                >
                                    Buy Now
                                </Links> */}
                            </div>
                        </div>
                    </section>
                </Element>
                <section className="products">
                    <div className="title">
                        <h4>Our Products</h4>
                        <p></p>
                    </div>
                    <div className="products-body">

                        <AliceCarousel
                            mouseTracking
                            items={items}
                            responsive={responsive}
                            controlsStrategy="alternate"
                            autoPlay={true}
                            infinite={true}
                            autoPlayInterval={5500}
                            disableDotsControls={true}
                            renderPrevButton={() => {
                                return <svg className='prev' id="Glyph" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 1.25c-5.9277 0-10.75 4.8223-10.75 10.75s4.8223 10.75 10.75 10.75 10.75-4.8223 10.75-10.75-4.8223-10.75-10.75-10.75zm-3.3721 15.0498c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121l-3.6113 3.3877zm8.6113-3.3877-3.6113 3.3877c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121z" /></svg>
                            }}
                            renderNextButton={() => {
                                return <svg className='next' id="Glyph" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 1.25c-5.9277 0-10.75 4.8223-10.75 10.75s4.8223 10.75 10.75 10.75 10.75-4.8223 10.75-10.75-4.8223-10.75-10.75-10.75zm-3.3721 15.0498c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121l-3.6113 3.3877zm8.6113-3.3877-3.6113 3.3877c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121z" /></svg>
                            }}
                        />


                    </div>
                </section>

                <div className="benefits">
                    <div className="title">
                        <h4>Health Benefits</h4>
                        <p></p>
                    </div>
                    <div className="benefits-body">
                        <div className="benefits-left">
                            <div className="benefits-card">
                                <h3>Skin Health</h3>
                                <p>Can help improve skin health due to its anti-inflammatory properties and essential fatty acids.</p>
                            </div>
                            <div className="benefits-card">
                                <h3>Anti-inflammatory</h3>
                                <p>Contains omega-3 and omega-6 fatty acids, which helps to reduce inflammation.</p>
                            </div>
                            <div className="benefits-card">
                                <h3>Cholesterol Control</h3>
                                <p>Contains linoleic acid and alpha-linolenic acid, which helps to maintain healthy cholesterol levels.</p>
                            </div>
                        </div>
                        <img src={benefits} alt="oil" />
                        <div className="benefits-left">
                            <div className="benefits-card">
                                <h3>Versatile Cooking Oil</h3>
                                <p>Has a neutral flavor and high smoke point, making it suitable for various cooking methods.</p>
                            </div>
                            <div className="benefits-card">
                                <h3>Hair Health</h3>
                                <p>May promote hair growth and prevent hair loss.</p>
                            </div>
                            <div className="benefits-card">
                                <h3>Cholesterol Control</h3>
                                <p>Contains linoleic acid and alpha-linolenic acid, which helps to maintain healthy cholesterol levels.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="blog">
                    <div className="title">
                        <h4>Our Blogs</h4>
                        <p></p>
                    </div>
                    <div className="blog-body">
                        {blogs.map(blog => (
                            <Link to={"/show/" + blog.id} className="blog-card">
                                <div className="blog-card-left">
                                    {/* <img key={blog.id} src={`data:image/jpeg;base64,${blog.Image}`} alt={blog.Title} /> */}
                                    <img src={blog.Image} alt={blog.Title} />
                                </div>

                                <div className="blog-card-right">
                                    <h3>{blog.Title}</h3>
                                    <div className='body' dangerouslySetInnerHTML={{ __html: blog.Body }}></div>
                                </div>
                            </Link>
                        ))}

                        {/* <div className="blog-card">
                            <img src={blog} alt="" />
                            <h3>Blog 1</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat delectus praesentium earum voluptatum quidem autem doloremque magnam accusamus! Aperiam dolorem non at exercitationem error nihil quam ullam perferendis quibusdam tempora!</p>
                        </div>

                        <div className="blog-card">
                            <img src={blog} alt="" />
                            <h3>Blog 1</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat delectus praesentium earum voluptatum quidem autem doloremque magnam accusamus! Aperiam dolorem non at exercitationem error nihil quam ullam perferendis quibusdam tempora!</p>
                        </div>

                        <div className="blog-card">
                            <img src={blog} alt="" />
                            <h3>Blog 1</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat delectus praesentium earum voluptatum quidem autem doloremque magnam accusamus! Aperiam dolorem non at exercitationem error nihil quam ullam perferendis quibusdam tempora!</p>
                        </div> */}
                    </div>
                </div>

                <div className="fruits">
                    <div className="fruits-text">
                        <h3>We also deal in Fruits & Vegetables</h3>
                        <a href="tel:918708779363">Call Us</a>
                    </div>
                </div>

                <section className="contact" id="buy">
                    <div className="title">
                        <h4>Buy Now</h4>
                        <p></p>
                    </div>
                    <div className="contact-body">
                        <img src={buy} alt="buy now" />
                        <form action="POST" ref={form}>
                            <input type="text" placeholder='Full Name'
                                name='Name'
                                value={userData.Name}
                                onChange={postUserData}
                                required
                            />
                            <input type="email" placeholder='Email Address'
                                name='Email'
                                value={userData.Email}
                                onChange={postUserData}
                                required
                            />
                            <input type="text" placeholder='Mobile'
                                name='Mobile'
                                value={userData.Mobile}
                                onChange={postUserData}
                                required
                            />
                            <input style={{ display: "none" }}
                                type="text"
                                name="productName"
                                value="Buy Now Form"
                            />
                            <input type="number" placeholder='Quantity' name='Quantity'
                                min={1}
                                value={userData.Quantity}
                                onChange={postUserData}
                                required
                            />
                            <select id="" className="form-control" name='Products'
                                value={userData.Products}
                                onChange={postUserData} >
                                <option value="retailer/self use" selected>Select Products</option>
                                <option value="Kachi Ghani Mustard Oil
">Kachi Ghani Mustard Oil
                                </option>
                                <option value="Refined Soyabean Oil
">Refined Soyabean Oil
                                </option>
                            </select>
                            <button onClick={SubmitData}>Send</button>
                        </form>
                    </div>
                </section>
            </div>
            <Footer />

        </>

    )
}

export default Home2