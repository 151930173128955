import React, { useState, useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import banner from "../Image/Untitled design.png"
import p1 from "../Image/Products/kachi ghani (premium).png"
import p2 from "../Image/Products/kachi ghani.png"
import { Link } from 'react-router-dom'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import fb from "../AdminPanel/Blog/firebase";
const DB = fb.firestore()
const Blogslist = DB.collection('MustardOilReviews');
const Blogslists = DB.collection('MustardOilReviews');

const MustardOil = () => {
    const [blogs2, Setblogs2] = useState([]);

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }

        const unsubscribe = Blogslists.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            // Update state
            Setblogs2(data);
        });

        // Detach listener
        return unsubscribe;

    }, []);


    const [review, setReview] = useState(false);
    const [product, setProduct] = useState(true);
    const [bg1, setbg1] = useState("#f1ece7");
    const [bg2, setbg2] = useState("#956B41");
    const [color1, setcolor1] = useState("#586067");
    const [color2, setcolor2] = useState("#e9e4d0");

    const clickdescription = () => {
        setProduct(true);
        setReview(false);
        setbg1("#f1ece7");
        setbg2("#956B41");
        setcolor1("#586067");
        setcolor2("#e9e4d0")
    }

    const clickreview = () => {
        setProduct(false);
        setReview(true);
        setbg1("#956B41");
        setbg2("#f1ece7");
        setcolor1("#e9e4d0");
        setcolor2("#586067");
    }

    const responsive = {
        0: { items: 1 },
        // 568: { items: 2 },
        // 1024: { items: 3 },
    };

    const items2 = [
        <Zoom>
            <img
                alt="Kachi Ghani Mustard Oil (Premium Quality)"
                src={p1}
                width="500"
            />
        </Zoom>,
        <Zoom>
            <img
                alt="Kachi Ghani Mustard Oil"
                src={p2}
                width="500"
            />
        </Zoom>
    ];

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [text, setText] = useState("");

    // add review
    const handleSubmit = async (e) => {
        e.preventDefault();
        Blogslist.doc(email).set({
            Name: name,
            Text: text,
        }).then((docRef) => {
            alert('Review Posted Successfully')
        }).catch((error) => {
            console.error("error:", error);
        });
    }
    // add review ends


    return (
        <div className='mustard'>
            <Header />
            <div className="banner">
                <img src={banner} alt="banner" />

                <div className="banner-text">
                    <h3>Kachi Ghani Mustard Oil</h3>
                    <h4><Link to={"/"}>Home</Link>/ Kachi Ghani Mustard Oil </h4>
                </div>
            </div>

            <div className="mustard-about">
                <div className="mustard-img">
                    <AliceCarousel
                        mouseTracking
                        items={items2}
                        responsive={responsive}
                        controlsStrategy="alternate"
                        autoPlay={true}
                        infinite={true}
                        autoPlayInterval={5500}
                        disableDotsControls={true}
                        renderPrevButton={() => {
                            return <svg className='prev' id="Glyph" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 1.25c-5.9277 0-10.75 4.8223-10.75 10.75s4.8223 10.75 10.75 10.75 10.75-4.8223 10.75-10.75-4.8223-10.75-10.75-10.75zm-3.3721 15.0498c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121l-3.6113 3.3877zm8.6113-3.3877-3.6113 3.3877c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121z" /></svg>
                        }}
                        renderNextButton={() => {
                            return <svg className='next' id="Glyph" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 1.25c-5.9277 0-10.75 4.8223-10.75 10.75s4.8223 10.75 10.75 10.75 10.75-4.8223 10.75-10.75-4.8223-10.75-10.75-10.75zm-3.3721 15.0498c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121l-3.6113 3.3877zm8.6113-3.3877-3.6113 3.3877c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121z" /></svg>
                        }}
                    />

                </div>
                <div className="mustard-text">
                    <h3>Kachi Ghani Mustard Oil (Premium Quality)</h3>
                    <p>Pure Kachi Ghani Mustard Oil, traditionally known as ‘Sarson Ka Tel, is cold-pressed from the finest quality mustard seeds.
                        Our dedication in maintaining purity ensures that this cooking oil retains its natural nutrients, robust aroma, and authentic flavors, staying true to the heart of traditional Indian cuisine. Pure cold-pressed Mustard oil is celebrated for its health benefits, particularly its wealth of monounsaturated and polyunsaturated fats, which support heart health. Ideal for Pickling, Frying, and Seasoning, Kachi Ghani Mustard Oil adds a unique pungency and depth to dishes, making it an indispensable addition to any kitchen focused on nutrition and taste.
                    </p>

                    <p><b>Quantity: </b> <span>500 ML</span> <span>1L</span>  </p>
                    <p><b>Use for:</b> Cooking</p>
                    <p><b>Processing type:</b> Cold Pressed</p>
                    {/* <p><b>Maximum Shelf Life:</b> 275 Days</p> */}
                    <a href="#">Buy Now</a>
                </div>
            </div>

            <div className="mustard-button">
                <button style={{ background: bg1, color: color1 }} onClick={clickdescription}>Description</button>
                <button style={{ background: bg2, color: color2 }} onClick={clickreview}>Reviews</button>
            </div>

            {product && (
                <div className="mustard-description">
                    <h3>Why Khadyan Mustard Oil?</h3>
                    <ul>
                        <li>Khadyan Kachi Ghani mustard oil has authentic & Traditional Taste.</li>
                        <li>It is a Cold Pressed Mustard oil.</li>
                        <li>Fortified with Vitamin A & D.</li>
                    </ul>

                    <h3>Free from Argemone Oil</h3>
                    <p>Argemone is an adulterant which is added in oil to increase the quantity</p>

                    <h3>Rich in MUFA & PUFA</h3>
                    <p>Monounsaturated fatty Acids (MUFA) and Polyunsaturated fatty acids (PUFA) have heart healthy benefits, Lowers the blood Cholesterol levels and lessen the risk of heart diseases</p>

                    <h3>What Does Cold Pressed Oil Mean?</h3>
                    <ul>
                        <li>Cold-pressed mustard oil is extracted by using the traditional wood-pressed method. Cold-pressing is a method that is as simple as just cleaning the seeds and crushing the seeds in our wooden Kohlus/ Kachi Ghani’s without the use of any heat which helps retain the nutrients and make oils healthy.
                        </li>
                        <li>Khadyan Mustard Oil is unrefined, 100% pure and natural and we neither use chemicals at any stage nor do we add preservatives like most of the commercial cooking oils on the market.</li>
                    </ul>

                    <p>Khadyan oil is rich in Omega-3-fatty acid and omega-6-fatty acid (PUFA)
                    </p>

                    <p>Khadyan is a rich source of PUFA which helps in lowering the BAD CHOLESTEROL (LDL)
                    </p>
                </div>
            )}

            {review && (

                <div className="mustard-review">

                    <div className="reviews">
                        <h2>Reviews</h2>
                        {blogs2.map(blog => (
                            <div className="review">
                                <h4>{blog.Name}</h4>
                                <p>{blog.Text}</p>
                            </div>
                        ))}
                    </div>

                    <h2>Write A Review</h2>
                    <form onSubmit={handleSubmit} className="mustard-write-review">
                        <label htmlFor="Name">Name</label>
                        <input type="text" name="" id="" value={name}
                            onChange={(e) => setName(e.target.value)} />

                        <label htmlFor="Name">Email</label>
                        <input type="text" name="" id="" value={email}
                            onChange={(e) => setEmail(e.target.value)} />

                        <label htmlFor="Name">Your Review</label>
                        <textarea name="" id="" rows='10'
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        >
                        </textarea>

                        <button type="submit">Submit</button>
                    </form>

                </div>
            )}
            <Footer />
        </div>
    )
}

export default MustardOil