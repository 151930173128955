import React, { useState, useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import banner from "../Image/refined bg.png"
import p1 from "../Image/Products/refined soyabean.png"
import p2 from "../Image/Products/product-info.png"
import { Link } from 'react-router-dom'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import fb from "../AdminPanel/Blog/firebase";
const DB = fb.firestore()
const Blogslist = DB.collection('RefinedOilReviews');
const Blogslists = DB.collection('RefinedOilReviews');

const RefinedOil = () => {

    const [blogs2, Setblogs2] = useState([]);

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }

        const unsubscribe = Blogslists.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            // Update state
            Setblogs2(data);
        });

        // Detach listener
        return unsubscribe;

    }, []);

    const [review, setReview] = useState(false);
    const [product, setProduct] = useState(true);
    const [bg1, setbg1] = useState("#f1ece7");
    const [bg2, setbg2] = useState("#956B41");
    const [color1, setcolor1] = useState("#586067");
    const [color2, setcolor2] = useState("#e9e4d0");

    const clickdescription = () => {
        setProduct(true);
        setReview(false);
        setbg1("#f1ece7");
        setbg2("#956B41");
        setcolor1("#586067");
        setcolor2("#e9e4d0")
    }

    const clickreview = () => {
        setProduct(false);
        setReview(true);
        setbg1("#956B41");
        setbg2("#f1ece7");
        setcolor1("#e9e4d0");
        setcolor2("#586067");
    }

    const responsive = {
        0: { items: 1 },
        // 568: { items: 2 },
        // 1024: { items: 3 },
    };

    const items = [
        <Zoom>
            <img
                alt="Refined Soyabean Oil"
                src={p1}
                width="500"
            />
        </Zoom>,
        <Zoom>
            <img
                alt="Refined Soyabean Oil"
                src={p2}
                width="500"
            />
        </Zoom>
    ];

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [text, setText] = useState("");

    // add review
    const handleSubmit = async (e) => {
        e.preventDefault();
        Blogslist.doc(email).set({
            Name: name,
            Text: text,
        }).then((docRef) => {
            alert('Review Posted Successfully')
        }).catch((error) => {
            console.error("error:", error);
        });
    }
    // add review ends

    return (
        <div className='mustard'>
            <Header />
            <div className="banner">
                <img src={banner} alt="banner" />

                <div className="banner-text">
                    <h3>Refined Soyabean Oil</h3>
                    <h4><Link to={"/"}>Home</Link>/ Refined Soyabean Oil</h4>
                </div>
            </div>

            <div className="mustard-about">
                <div className="mustard-img">
                    <AliceCarousel
                        mouseTracking
                        items={items}
                        responsive={responsive}
                        controlsStrategy="alternate"
                        autoPlay={true}
                        infinite={true}
                        autoPlayInterval={5500}
                        disableDotsControls={true}
                        renderPrevButton={() => {
                            return <svg className='prev' id="Glyph" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 1.25c-5.9277 0-10.75 4.8223-10.75 10.75s4.8223 10.75 10.75 10.75 10.75-4.8223 10.75-10.75-4.8223-10.75-10.75-10.75zm-3.3721 15.0498c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121l-3.6113 3.3877zm8.6113-3.3877-3.6113 3.3877c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121z" /></svg>
                        }}
                        renderNextButton={() => {
                            return <svg className='next' id="Glyph" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m12 1.25c-5.9277 0-10.75 4.8223-10.75 10.75s4.8223 10.75 10.75 10.75 10.75-4.8223 10.75-10.75-4.8223-10.75-10.75-10.75zm-3.3721 15.0498c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121l-3.6113 3.3877zm8.6113-3.3877-3.6113 3.3877c-.1445.1357-.3291.2031-.5127.2031-.2002 0-.3994-.0796-.5469-.2368-.2832-.3022-.2686-.7769.0342-1.0601l3.417-3.2061-3.417-3.2061c-.3027-.2832-.3174-.7578-.0342-1.0601.2832-.3018.7578-.3169 1.0596-.0337l3.6113 3.3882c.251.2354.3955.5674.3955.9116s-.1445.6763-.3955.9121z" /></svg>
                        }}
                    />

                </div>
                <div className="mustard-text">
                    <h3>Refined Soyabean Oil</h3>
                    <p>Soy oil is the oil extracted from soybeans. Crude soybean oil undergoes further refinement- degumming, refining, bleaching, and deodorizing and forms refined soy oil. Soybean oil is the leading vegetable oil traded in the international markets, next only to palm oil. It has a share of 24% in total vegetable oil consumption. Soy oil accounts for nearly 75% out of total vegetable oil in-take in the US. Soy oil is cholesterol-free and high in polyunsaturated fat. Soy oil is light in flavor and has almost no odor.</p>

                    <p><b>Quantity: </b> <span>500 ML</span> <span>1L</span>  </p>
                    <p><b>Use for:</b> Cooking</p>
                    <p><b>Processing type:</b> Cold Pressed</p>
                    {/* <p><b>Maximum Shelf Life:</b> 275 Days</p> */}
                    <a href="#">Buy Now</a>
                </div>
            </div>

            <div className="mustard-button">
                <button style={{ background: bg1, color: color1 }} onClick={clickdescription}>Description</button>
                <button style={{ background: bg2, color: color2 }} onClick={clickreview}>Reviews</button>
            </div>

            {product && (
                <div className="mustard-description">
                    <h3>Why Khadyan Soyabean Oil?</h3>
                    <ul>
                        <li>Neutral flavor: This makes it suitable for various cooking methods, including frying, baking, and sautéing.</li>
                        <li>High smoke point: It can withstand high temperatures without breaking down, making it ideal for deep frying.</li>
                        <li>Rich in polyunsaturated fatty acids (PUFAs): These fats are considered heart-healthy as they help lower bad cholesterol levels.</li>
                        <li>Source of omega-3 fatty acids: These essential fats contribute to overall heart health and brain function.</li>
                        <li>Contains vitamin E: This antioxidant helps protect cells from damage.</li>
                    </ul>

                    <h3>Health Benefits</h3>
                    <ul>
                        <li>Heart health: The PUFAs and omega-3 fatty acids in soybean oil can help lower bad cholesterol and reduce the risk of heart disease.</li>
                        <li>Brain health: Omega-3 fatty acids are essential for brain function and development.</li>
                        <li>Antioxidant properties: Vitamin E helps protect cells from oxidative damage.</li>
                    </ul>

                    <p>High in omega-6 fatty acids: While omega-6 fatty acids are essential, excessive intake can lead to inflammation. It's important to balance omega-6 and omega-3 intake.</p>

                </div>
            )}

            {review && (
                <div className="mustard-review">
                    <div className="reviews">
                        <h2>Reviews</h2>
                        {blogs2.map(blog => (
                            <div className="review">
                                <h4>{blog.Name}</h4>
                                <p>{blog.Text}</p>
                            </div>
                        ))}
                    </div>
                    <h2>Write A Review</h2>
                    <form onSubmit={handleSubmit} className="mustard-write-review">
                        <label htmlFor="Name">Name</label>
                        <input type="text" name="" id="" value={name}
                            onChange={(e) => setName(e.target.value)} />

                        <label htmlFor="Name">Email</label>
                        <input type="text" name="" id="" value={email}
                            onChange={(e) => setEmail(e.target.value)} />

                        <label htmlFor="Name">Your Review</label>
                        <textarea name="" id="" rows='10'
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        >
                        </textarea>

                        <button type="submit">Submit</button>
                    </form>

                </div>
            )}
            <Footer />
        </div>
    )
}

export default RefinedOil