import React, { useState, useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import "../Sass/Home.scss"
import { Link } from 'react-router-dom'
import banner from "../Image/bg.jpg"
import fb from "../AdminPanel/Blog/firebase";
import blog from "../Image/2.png"
const DB = fb.firestore()
const Blogslist = DB.collection('blogs');
const BlogsCategory = DB.collection('blog-categories');
const Blog = () => {

    const [blogs, Setblogs] = useState([]);
    
    useEffect(() => {
        // 👇️ scroll to top on page load

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const unsubscribe = Blogslist.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            // Update state
            Setblogs(data);
        });

        // Detach listener
        return unsubscribe;
    }, []);


    return (
        <>
            <Header />
            <div className="blog-page mustard">
                <div className="banner">
                    <img src={banner} alt="banner" />

                    <div className="banner-text">
                        <h3>Blogs</h3>
                        <h4><Link to={"/"}>Home</Link>/ Blogs </h4>
                    </div>
                </div>
                {/* <div className="blog-body">
                    <div className="blog-card">
                        <img src={imageUrl} alt="" />
                        <h3>Blog 1</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat delectus praesentium earum voluptatum quidem autem doloremque magnam accusamus! Aperiam dolorem non at exercitationem error nihil quam ullam perferendis quibusdam tempora!</p>
                    </div>

                    <div className="blog-card">
                        <img src={blog} alt="" />
                        <h3>Blog 1</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat delectus praesentium earum voluptatum quidem autem doloremque magnam accusamus! Aperiam dolorem non at exercitationem error nihil quam ullam perferendis quibusdam tempora!</p>
                    </div>

                    <div className="blog-card">
                        <img src={blog} alt="" />
                        <h3>Blog 1</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat delectus praesentium earum voluptatum quidem autem doloremque magnam accusamus! Aperiam dolorem non at exercitationem error nihil quam ullam perferendis quibusdam tempora!</p>
                    </div>
                </div> */}
                <div className="blog-body">
                    <div className="blogs">
                        {blogs.map(blog => (
                            <Link to={"/show/" + blog.id} className="blog-card">
                                <div className="blog-card-left">
                                {/* <img key={blog.id} src={`data:image/jpeg;base64,${blog.Image}`} alt={blog.Title} /> */}
                                <img src={blog.Image} alt={blog.Title} />
                               </div>

                                <div className="blog-card-right">
                                    <h3>{blog.Title}</h3>
                                    {/* <div className='body' dangerouslySetInnerHTML={{ __html: blog.Body }}></div> */}
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Blog