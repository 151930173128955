import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"
// import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyA0ZLycZc6lsTj3nbDxIRxNpzmRIuwVZuE",
    authDomain: "khadyan-4f6e4.firebaseapp.com",
    projectId: "khadyan-4f6e4",
    storageBucket: "khadyan-4f6e4.firebasestorage.app",
    messagingSenderId: "870591812094",
    appId: "1:870591812094:web:a48e9eb142dd14406e30af",
    measurementId: "G-JFX44D5W61"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
