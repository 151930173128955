import React, { useState, useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import "../Sass/Home.scss"
import { useParams, Link } from 'react-router-dom';
import fb from "../AdminPanel/Blog/firebase";
const DB = fb.firestore()
const Blogslist = DB.collection('blogs');

const BlogDetails = () => {

    const { id } = useParams();
    const [blogs, Setblogs] = useState([]);
    Blogslist.doc(id).get().then((snapshot) => {
        const data = snapshot.data()
        Setblogs(data);
    });

    useEffect(() => {
        // 👇️ scroll to top on page load

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Header />
            <div className="blog-details">

                <div className="blog-banner">
                    <img src={blogs.Image} alt={blogs.Title} />
                    <div className="banner-text">
                        <h3>{blogs.Title}</h3>
                        <div className="date">
                            <h5><svg id="Capa_1" enable-background="new 0 0 512.228 512.228" height="512" viewBox="0 0 512.228 512.228" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m413.333 39.447h-19.106v-19.333c0-11.046-8.954-20-20-20s-20 8.954-20 20v19.333h-196.227v-19.333c0-11.046-8.954-20-20-20s-20 8.954-20 20v19.333h-19.105c-54.531 0-98.895 44.364-98.895 98.894v274.878c0 54.531 44.364 98.895 98.895 98.895h314.439c54.53 0 98.894-44.364 98.894-98.895v-274.878c0-54.53-44.364-98.894-98.895-98.894zm-314.438 40h19.105v39c0 11.046 8.954 20 20 20s20-8.954 20-20v-39h196.228v39c0 11.046 8.954 20 20 20s20-8.954 20-20v-39h19.106c32.474 0 58.894 26.42 58.894 58.894v19.106h-432.228v-19.106c0-32.474 26.42-58.894 58.895-58.894zm314.438 392.667h-314.438c-32.475 0-58.895-26.42-58.895-58.895v-215.772h432.228v215.772c0 32.475-26.42 58.895-58.895 58.895zm-235.666-196c0 11.046-8.954 20-20 20h-39.333c-11.046 0-20-8.954-20-20s8.954-20 20-20h39.333c11.045 0 20 8.954 20 20zm236.228 0c0 11.046-8.954 20-20 20h-39.333c-11.046 0-20-8.954-20-20s8.954-20 20-20h39.333c11.045 0 20 8.954 20 20zm-118.228 0c0 11.046-8.954 20-20 20h-39.333c-11.046 0-20-8.954-20-20s8.954-20 20-20h39.333c11.045 0 20 8.954 20 20zm-118 118c0 11.046-8.954 20-20 20h-39.333c-11.046 0-20-8.954-20-20s8.954-20 20-20h39.333c11.045 0 20 8.954 20 20zm236.228 0c0 11.046-8.954 20-20 20h-39.333c-11.046 0-20-8.954-20-20s8.954-20 20-20h39.333c11.045 0 20 8.954 20 20zm-118.228 0c0 11.046-8.954 20-20 20h-39.333c-11.046 0-20-8.954-20-20s8.954-20 20-20h39.333c11.045 0 20 8.954 20 20z" /></g></svg>{blogs.Date}</h5>
                        </div>
                    </div>
                </div>
                <div className="blog-details-body">
                    <div className='body' dangerouslySetInnerHTML={{ __html: blogs.Body }}></div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default BlogDetails